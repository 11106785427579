import axios from "axios";
import { getBackendLinkUrl } from "./getBackendUrl";

class LinkService {
    constructor(baseURL) {
      this.baseURL = baseURL;
      this.api = axios.create({
        baseURL,
      });
    }
  // Generate a short link
  shortLink(longUrl) {
    return this.api.post("/shortlink", { longUrl });
  }

  // Redirect based on short code
  getLongLink(smallCode) {
    return this.api.get(`/${smallCode}`);
  }
}

export default new LinkService(getBackendLinkUrl());